document.addEventListener('DOMContentLoaded', function () {
  setTimeout(() => {
    document.querySelector('.section-head').classList.add('loaded');
  }, 100);
  /* ==== header state =====*/
  window.addEventListener('scroll', function () {
    var siteHeader = document.querySelector('.header');
    if (window.scrollY > 1) {
      siteHeader.classList.add('scroll');
    } else {
      siteHeader.classList.remove('scroll');
    }
  });
  /* ==== header burger =====*/
  const burger_menu = document.querySelector('.header > .container');
  document.querySelector('.header_burger-open').addEventListener('click', function () {
    burger_menu.classList.add('open');
    document.body.classList.add('hidden');
  });
  document.querySelector('.header_burger-close').addEventListener('click', function () {
    burger_menu.classList.remove('open');
    document.body.classList.remove('hidden');
  });
  burger_menu.addEventListener('click', function ( event ) {
    if (event.target === this) {
      burger_menu.classList.remove('open');
      document.body.classList.remove('hidden');
    }
  });
  document.querySelectorAll('.header_menu__item a').forEach(function ( element ) {
    element.addEventListener('click', function () {
      burger_menu.classList.remove('open');
      document.body.classList.remove('hidden');
    });
  });
  /* ==== custom pagination =====*/
  const pagination = document.querySelector('.pagination');
  if (pagination !== null) {
    if (document.querySelector('.page-numbers.current').textContent === '1') {
      document.querySelector('.page-numbers').classList.add('first');
    }
  }
  /* ==== lang mob swicher =====*/
  document.querySelector('.lang-swither_current').addEventListener('click', function () {
    document.querySelector('.lang-swither_list').classList.toggle('open');
  });
  // modals ==============================
  let teamIndex;
  const closeBurger = document.querySelector('.slider-team_modal__close');
  if (closeBurger !== null) {
    closeBurger.addEventListener('click', function () {
      document.querySelector('.slider-team_modal').classList.remove('open');
    });
  }
  const teamItem = document.querySelectorAll('.leadership_team .popup-info');
  teamItem.forEach(function ( item ) {
    item.addEventListener("click", ( e ) => {
      let index = e.currentTarget.closest('.swiper-slide').getAttribute('aria-label').charAt(0);
      document.querySelector('.slider-team_modal').classList.add('open');
      teamIndex = index - 1;
      let teamSlider = new Swiper('.slider-team_modal .container', {
        speed: 600,
        slidesPerView: 1,
        slidesPerGroup: 1,
        spaceBetween: 40,
        loop: false,
        keyboard: true,
        mousewheel: {
          forceToAxis: true,
        },
        updateOnWindowResize: true,
        initialSlide: teamIndex,
        allowTouchMove: false,
        navigation: {
          nextEl: '.btn-nav.btn-next', prevEl: '.btn-nav.btn-prev',
        },
      });
    })
  });

  /* ==== swipper =====*/
  if (document.querySelector('.slider-team') !== null) {
    let teamBulet = new Swiper('.slider-team', {
      speed: 600,
      slidesPerView: 2,
      slidesPerGroup: 1,
      spaceBetween: 80,
      loop: false,
      keyboard: true,
      watchState: true,
      watchSlidesProgress: true,
      allowTouchMove: true,
      mousewheel: {
        forceToAxis: true,
      },
      updateOnWindowResize: true,
      navigation: {
        nextEl: '.slider-team_btn--next', prevEl: '.slider-team_btn--prev',
      },
      breakpoints: {
        1024: {
          allowTouchMove: false,
        }
      }
    });
  }
  // scroll animate ==========================
  var who_we_are_p = document.querySelectorAll('.who-we-are_text');
  who_we_are_p.forEach(function ( element ) {
    var paragraphsInsideElement = element.querySelectorAll('p');
    paragraphsInsideElement.forEach(function ( paragraph ) {
      paragraph.classList.add('element-animation');
    });
  });
  var inViewEl = document.querySelectorAll('.section-quest .item,.ocean-ecosystems_content,.empowers-section_content, .section-representative_content .text, .section-representative_content .img, .section-partners .text, .feedback_feedback, .frame-img_3, .home-head_txt-bottom p, .home-head_circle, .empowers-section_item, .why-journey .container, .home-head_explore .text, .strategic-section .item, .title-section, .strategic-section .subtitle, .section-partners_content .item, .section-quest_title, .who-we-are_head .circle-decore_3, .why-journey_item, .feedback_title, .ocean-ecosystems_item, .home-head_photo, .home-head_descript, .home-head_subtitle, .empowers-section_title, .empowers-section_text, .what-we-do_content .descript, .what-we-do_items .item, .what-we-do_items .text, .slider-team .item, .scientists .item, .news_block_item, .section-single-news .container > *, .section-single-news .container p, .big-text p, .ocean-ecosystems_descript, .ocean-ecosystems_subtitle, .deep-ocean_text, .deep-ocean_item, .exploring-deep_content p, .benefits-eploration_item, .benefits-eploration_col img, .benefits-eploration_descript, .types-exploration-wrap .content, .blue-economy .descript, .blue-economy .item, .value-economy_item, .value-economy_descript, .deep-ocean, .types-exploration .descript, .wrap-circle_text .item, .wrap-circle_text .title-2, .wrap-circle.--column .wrap-circle_text, .wrap-circle.--column p, .home-head_explore .title');
  inViewEl.forEach(function ( view ) {
    view.classList.add('element-animation');
  });

  const viewbox = document.querySelectorAll('.element-animation');
  const containerHeight = window.innerHeight;
  function scrollBlock() {
    const direction = (this.oldScroll > this.scrollY) ? "up" : "down";
    this.oldScroll = this.scrollY;
    viewbox.forEach(( element, index ) => {
      element.viewName = element.viewName || "";
      const rect = element.getBoundingClientRect();
      const top = rect.top + 5;
      const bottom = rect.bottom - 5;
      if (direction == "down") {
        if (top > 0 && top < containerHeight) {
          element.viewName = "inview-bottom";
          element.classList.add('element-show');
        } else if (top < 0 && bottom < 0) {
          element.viewName = "outview-top";
          element.classList.remove('element-show');
        }
      } else {
        if (top > containerHeight) {
          element.viewName = "outview-bottom";
          element.classList.remove('element-show');
        } else if (top < 0 && bottom > 0) {
          element.viewName = "inview-top";
          element.classList.add('element-show');
        }
      }
      element.setAttribute('data-view', element.viewName);
    });
  }
  scrollBlock();
  window.addEventListener("scroll", scrollBlock);

  // img-svg ==========================
  if (document.querySelectorAll('img.img-svg').length) {
    document.querySelectorAll('img.img-svg').forEach(function ( img ) {
      var imgClass = img.getAttribute('class');
      var imgURL = img.getAttribute('src');
      fetch(imgURL)
        .then(function ( response ) {
          return response.text();
        })
        .then(function ( data ) {
          var parser = new DOMParser();
          var xmlDoc = parser.parseFromString(data, 'text/xml');
          var svg = xmlDoc.querySelector('svg');
          if (typeof imgClass !== 'undefined') {
            svg.setAttribute('class', imgClass + ' replaced-svg');
          }
          svg.removeAttribute('xmlns:a');
          if (!svg.getAttribute('viewBox') && svg.getAttribute('height') && svg.getAttribute('width')) {
            svg.setAttribute('viewBox', '0 0 ' + svg.getAttribute('height') + ' ' + svg.getAttribute('width'));
          }
          img.parentNode.replaceChild(svg, img);
        })
        .catch(function ( error ) {
          console.error('Error fetching SVG:', error);
        });
    });
  }

  // anchors ==========================
  if (document.querySelectorAll('a[href^="#"]').length) {
    document.querySelectorAll('a[href^="#"]').forEach(anchor => {
      anchor.addEventListener('click', function ( e ) {
        e.preventDefault();
        document.body.classList.remove('hidden');
        document.querySelector('.header > .container').classList.remove('open');
        let hashVal = e.target.getAttribute('href');
        let currentSet = document.querySelector(hashVal).getBoundingClientRect().top + window.scrollY - 80;
        window.scroll({
          top: currentSet, left: 0, behavior: 'smooth'
        });
      });
    });
  }

  //animation circle ==============================
  function circleDecore() {
    let circle_decore = document.querySelectorAll(".circle-decore, .section-representative_content .img, .home-head_circle, .home-head_photo");
    circle_decore.forEach(function ( el ) {
      let div = document.createElement('div');
      div.classList.add('circle-gradient');
      el.append(div);
    });
    let rotateEl = document.querySelectorAll(".circle-gradient");
    const scrollRotate = () => {
      rotateEl.forEach(function ( element ) {
        element.style.transform = "rotate(" + window.pageYOffset/3 + "deg)";
      });
    }

    window.onscroll = function () {
      scrollRotate();
    };
  }
  circleDecore();

  // accardeon ==========================
  const acardeon = document.querySelectorAll(".types-exploration-wrap .content");
  acardeon.forEach(el => {
    let ths = el;
    let acardeonItem = ths.querySelector('.types-exploration_acardeon');
    let acardeonH = el.offsetHeight;
    let acardeon_contentH = ths.querySelector('.types-exploration_acardeon__content').offsetHeight;
    let acardeonImg = ths.querySelector('.bg-img img');
    let acardeonFullH = acardeonH + acardeon_contentH;
    let acardeonTitle = ths.querySelector('.types-exploration_acardeon__title');
    let acardeon_check = ths.querySelector('.types-exploration_acardeon_h');
    acardeonImg.style.height = acardeonFullH + "px";
    acardeonTitle.addEventListener("click", function () {
      acardeonItem.classList.toggle('open');
      if (acardeonItem.classList.contains('open')) {
        acardeon_check.style.height = acardeon_contentH + "px";
      } else {
        acardeon_check.style.height = "0";
      }
    });
  });
  console.log(acardeon)

  // counter animate ==========================
  // class animatedCounter {
  //   constructor() {
  //     this.counts = document.querySelectorAll('.ocean-counter');
  //     this.initCount();
  //   }
  //
  //   counter() {
  //     let speed = 100;
  //     this.counts.forEach(item => {
  //       let final_count = +item.dataset.count;
  //       let init_count = +item.innerText;
  //       let increment_number = Math.fround(final_count/speed);
  //       const updateNumber = () => {
  //         init_count += increment_number;
  //         if (Number.isInteger(final_count)) {
  //           item.innerText = init_count.toFixed(0);
  //         } else {
  //           item.innerText = init_count.toFixed(1);
  //         }
  //         if (init_count < final_count) {
  //           setTimeout(() => {
  //             updateNumber()
  //           }, 10)
  //         }
  //       }
  //       updateNumber();
  //     });
  //   }
  //
  //   reCount() {
  //     document.querySelectorAll('.ocean-counter').forEach(( item ) => {
  //       item.innerHTML = '0';
  //     })
  //   }
  //
  //   initCount() {
  //     let checkCounter = false;
  //     const checkCounterInView = () => {
  //       let counts = document.querySelectorAll('.counter-trigger[data-view="inview-bottom"]').length;
  //       let counts_top = document.querySelectorAll('.counter-trigger[data-view="outview-bottom"]').length;
  //       if (counts > 0 && checkCounter === false) {
  //         checkCounter = true;
  //         this.counter();
  //       }
  //       if (counts_top > 0) {
  //         this.reCount();
  //         checkCounter = false;
  //       }
  //     }
  //     checkCounterInView();
  //     document.addEventListener("scroll", checkCounterInView);
  //   }
  // }

  class animatedCounter {
    constructor() {
      this.counts = document.querySelectorAll('.ocean-counter');
      this.initCount();
    }

    counter() {
      let speed = 100;
      this.counts.forEach(item => {
        let final_count = +item.dataset.count;
        let init_count = +item.innerText;
        let increment_number = Math.fround(final_count/speed);
        const updateNumber = () => {
          init_count += increment_number;
          if (Number.isInteger(final_count)) {
            item.innerText = init_count.toFixed(0);
          } else {
            item.innerText = init_count.toFixed(1);
          }
          if (init_count < final_count) {
            setTimeout(() => {
              updateNumber()
            }, 10);
          }
        }
        updateNumber();
      });
    }

    reCount() {
      document.querySelectorAll('.ocean-counter').forEach(( item ) => {
        item.innerHTML = '0';
      })
    }

    async initCount() {
      let checkCounter = false;
      const checkCounterInView = () => {
        let counts = document.querySelectorAll('.counter-trigger[data-view="inview-bottom"]').length;
        let counts_top = document.querySelectorAll('.counter-trigger[data-view="outview-bottom"]').length;
        if (counts > 0 && checkCounter === false) {
          checkCounter = true;
          this.counter();
        }
        if (counts_top > 0) {
          this.reCount();
          checkCounter = false;
        }
      }
      await checkCounterInView();
      document.addEventListener("scroll", checkCounterInView);
    }
  }

  if (document.querySelectorAll('.ocean-counter').length) {
    new animatedCounter();
  }

  // split text for animation
  function textWrite( animatedText ) {
    // Wrap every letter in a span
    animatedText.forEach(( textWrapper, idx ) => {
      let text = textWrapper.innerText.split(' ');
      textWrapper.style.opacity = '1';
      textWrapper.innerHTML = '';
      for (let i = 0; i < text.length; i++) {
        const span = document.createElement('span');
        let word = text[i].split('');
        for (let j = 0; j < word.length; j++) {
          const spanWord = document.createElement('span');
          spanWord.setAttribute('class', `el-${idx}-letter`);
          spanWord.style.opacity = '0';
          spanWord.innerHTML = word[j];
          span.append(spanWord)
        }
        textWrapper.append(span);
      }
    });
  }

  function textWriteAR( animatedText ) {
    // Wrap every letter in a span
    animatedText.forEach(( textWrapper, idx ) => {
      let text = textWrapper.innerText.split(' ');
      textWrapper.style.opacity = '1';
      textWrapper.innerHTML = '';
      for (let i = 0; i < text.length; i++) {
        const span = document.createElement('span');
        span.setAttribute('class', `el-${idx}-letter`);
        span.style.opacity = '0';
        span.innerHTML = text[i];
        textWrapper.append(span);
      }
    });
  }

  function animatedText() {
    let tl = anime
      .timeline({
        loop: false,
      });
    tl.add({
        targets: [".writeText .el-0-letter"],
        opacity: [{value: [0, 0]}, {value: [0.5, 1]}],
        duration: 1000,
        delay: ( el, i ) => 94*(i + 1)
      })
      .add({
        targets: ".writeText.el-0", opacity: 0, duration: 600, easing: "linear", delay: 500
      })
      .add({
        targets: ".writeText .el-1-letter",
        opacity: [{value: [0, 0]}, {value: [0.5, 1]}],
        duration: 1000,
        delay: ( el, i ) => 95*(i + 1)
      })
      .add({
        targets: ".writeText.el-1", opacity: 0, duration: 600, easing: "linear", delay: 500
      })
      .add({
        targets: ".writeText .el-2-letter",
        opacity: [{value: [0, 0]}, {value: [0.5, 1]}],
        duration: 1000,
        delay: ( el, i ) => 94*(i + 1)
      })
      .add({
        targets: ".writeText.el-2", opacity: 0, duration: 600, easing: "linear", delay: 1000
      });
  }

  function animatedTextAR() {
    let tl = anime
      .timeline({
        loop: false,
      });
    tl.add({
        targets: [".writeText .el-0-letter"],
        opacity: [0, 1],
        duration: 2500,
        delay: ( el, i ) => 164*(i + 1)
      })
      .add({
        targets: ".writeText.el-0", opacity: 0, duration: 600, easing: "linear", delay: 500
      })
      .add({
        targets: ".writeText .el-1-letter",
        opacity: [0, 1],
        duration: 2500,
        delay: ( el, i ) => 164*(i + 1)
      })
      .add({
        targets: ".writeText.el-1", opacity: 0, duration: 600, easing: "linear", delay: 500
      })
      .add({
        targets: ".writeText .el-2-letter",
        opacity: [0, 1],
        duration: 2500,
        delay: ( el, i ) => 164*(i + 1)
      })
      .add({
        targets: ".writeText.el-2", opacity: 0, duration: 600, easing: "linear", delay: 500
      });
  }

  let videoWho = document.querySelector('.who-we-are-section-quest .section-head_bg video'),
      imageWho = document.querySelector('.who-we-are-section-quest .section-head_bg img');

  if(document.querySelector('.who-we-are-section-quest') !== null) {
    videoWho.play().then(() => {
      imageWho.style.display = 'none';
    }).catch(error => {
      videoWho.style.display = 'none';
      imageWho.style.display = 'block';
    });
  }

  function initVideoPlayback() {
    if (document.querySelector('.video-earth') !== null) {
      let videoEarth = document.querySelector('.video-earth'),
          imageEarth = document.querySelector('.image-earth'),
          preloaderEarth = document.querySelector('.icon-preload-earth'),
        videoWrap = document.querySelector('.video-wrap'),
          imageWrap = document.querySelector('.image-wrap'),
        videoBlock = document.querySelector('.video-ea'),
        videoSubmarine = document.querySelector('.video-submarine'),
        pageWrap = document.querySelector('.page-wrapper'),
        skipButton = document.querySelector('.play-button'),
        itemMenu = document.querySelectorAll('.header_menu__item a'),
        textWrappers = document.querySelectorAll(".writeText");

      let videoTop_desktop = videoWrap.dataset.desktop;
      let videoTop_tablet = videoWrap.dataset.tablet;
      let videoTop_mob = videoWrap.dataset.mob;

      let videoBottom_desktop = videoSubmarine.dataset.desktop;
      let videoBottom_tablet = videoSubmarine.dataset.tablet;
      let videoBottom_mob = videoSubmarine.dataset.mob;

      let currentTime;
      let firstLoad = true;

      if (window.innerWidth >= 1024) {
        videoWrap.setAttribute("src", videoTop_desktop);
        videoSubmarine.setAttribute("src", videoBottom_desktop);
      }
      if (window.innerWidth > 480 && window.innerWidth < 1024) {
        videoWrap.setAttribute("src", videoTop_tablet);
        videoSubmarine.setAttribute("src", videoBottom_tablet);
      }
      if (window.innerWidth <= 480) {
        videoWrap.setAttribute("src", videoTop_mob);
        videoSubmarine.setAttribute("src", videoBottom_mob);
      }
      videoWrap.load();
      videoSubmarine.load();

      let checkPlay = true;
      const videoResolution = () => {
        if (checkPlay === true) {
          if (window.innerWidth >= 1024) {
            videoWrap.setAttribute("src", videoTop_desktop);
            videoSubmarine.setAttribute("src", videoBottom_desktop);
          }
          if (window.innerWidth > 480 && window.innerWidth < 1024) {
            videoWrap.setAttribute("src", videoTop_tablet);
            videoSubmarine.setAttribute("src", videoBottom_tablet);
          }
          if (window.innerWidth <= 480) {
            videoWrap.setAttribute("src", videoTop_mob);
            videoSubmarine.setAttribute("src", videoBottom_mob);
          }

          checkPlay = false;
          videoWrap.play();
          videoSubmarine.play();
        }
      }

      let timeLoad;
      let prevWidth = window.innerWidth;
      window.addEventListener("resize", ( event ) => {
        checkPlay = true;
        if (event.target.innerWidth !== prevWidth) {
          clearTimeout(timeLoad);
          prevWidth = window.innerWidth;

          timeLoad = setTimeout(() => {
            videoResolution();
          }, 1000);
        }
      });

      const playVideo = () => {
        skipButton.classList.add('timeline');
        window.history.scrollRestoration = 'manual'

        videoEarth.addEventListener('timeupdate', function () {
          currentTime = videoEarth.currentTime;
          if (currentTime >= videoEarth.duration - 2) {
            videoWrap.classList.add('loaded');
            videoResolution();
          }
          if (currentTime >= videoEarth.duration - 1) {
            pageWrap.classList.remove('hidden');
            videoBlock.classList.add('finished');
            pageWrap.classList.add('loaded');
            skipButton.classList.remove('timeline');
            document.querySelector('.home-head_subtitle').classList.remove('element-show');
            document.querySelector('.home-head_circle').classList.remove('element-show');
          }
          if (currentTime === videoEarth.duration) {
            document.body.style.overflow = "auto";
            setTimeout(() => {
              videoBlock.style.zIndex = "-1";
            }, 500);
          }
        });
      }

      videoWrap.play().then(() => {
        imageWrap.style.display = 'none';
      }).catch(error => {
        videoWrap.style.display = 'none';
        imageWrap.style.display = 'block';
      });

      const {
        host, hostname, href, origin, pathname, port, protocol, search
      } = window.location

      const skipIntro = () => {
        videoResolution();
        skipButton.classList.remove('timeline');

        setTimeout(()=> {
          document.querySelector('.home-head_subtitle').classList.remove('element-show');
          document.querySelector('.home-head_circle').classList.remove('element-show');
          videoBlock.style.transform = "translateY(-100%)";
          document.body.style.overflow = "auto";
          pageWrap.classList.remove('hidden');
          pageWrap.classList.add('loaded');
          videoWrap.classList.add('loaded');
          videoEarth.pause();
        }, 500);
      }

      preloaderEarth.style.opacity = 1;
      function hideLoader() {

        preloaderEarth.style.opacity = 0;
        preloaderEarth.classList.add('hide');

        setTimeout(function() {
          videoEarth.play().then(() => {
            imageEarth.style.display = 'none';
          }).catch(error => {
            videoEarth.style.display = 'none';
            imageEarth.style.display = 'block';
            skipIntro();
          });
        }, 1000);
      }

      videoEarth.addEventListener('canplaythrough', hideLoader);
      setTimeout(function() {
        if (preloaderEarth.style.opacity != 0) {
          hideLoader();
        }
      }, 10000);

      videoEarth.onplay = function () {
        playVideo();

        let arLink = `${origin}/ar`;
        let link = href.split('').slice(0, origin.length + 3).join('');
        if (arLink === link) {
          textWriteAR(textWrappers);
          setTimeout(() => {
            document.querySelector('.video-ea_text').style.opacity = '1';
            animatedTextAR();
          }, 100);
        } else {
          textWrite(textWrappers);
          setTimeout(() => {
            document.querySelector('.video-ea_text').style.opacity = '1';
            animatedText();
          }, 100);
        }
      };

      itemMenu.forEach(link => {
        link.addEventListener("click", ( e ) => {
          if (e.currentTarget.getAttribute("href") === '#feedback') skipIntro();
        });
      });

      skipButton.addEventListener("click", () => {
        skipIntro();
      });

      const returnEarth = () => {
        window.addEventListener("scroll", () => {
          let timer;
          if (window.scrollY === 0) {
            window.clearTimeout(timer);
            timer = setTimeout(() => {
              firstLoad = false;
              if (window.scrollY === 0 && firstLoad === false) {
                videoWrap.pause();
                videoEarth.load();
                // videoEarth.play();
                document.body.style.overflow = "hidden";
                videoBlock.style.transform = "translateY(0)";
                videoBlock.classList.remove('loaded');
                document.querySelector('.home-head_subtitle').classList.remove('element-show');
                document.querySelector('.home-head_circle').classList.remove('element-show');
                pageWrap.classList.remove('loaded');
                videoWrap.classList.remove('loaded');
                videoBlock.style.zIndex = "5";
              }
            }, 2000);
          } else {
            firstLoad = true;
          }
        });
      }
    }
  }
  initVideoPlayback();

  function titleHomeExplore() {
    const {
      host, hostname, href, origin, pathname, port, protocol, search
    } = window.location;

    let arLink = `${origin}/ar`;
    let link = href.split('').slice(0, origin.length + 3).join('');
    let textWrap = document.querySelectorAll('.home-head_explore .title');

    if (arLink === link) {
      textWriteAR(textWrap);
    } else {
      textWrite(textWrap);
    }

    let bool = false;
    const textAnimated = () => {
      let tl = anime
        .timeline({
          loop: false,
        });
      tl.add({
        targets: [".home-head_explore .el-0-letter"],
        opacity: [0, 1],
        scale: [0, 1],
        duration: 1000,
        delay: ( el, i ) => 34*(i + 1)
      });
    }
    const textAnimatedAR = () => {
      let tl = anime
        .timeline({
          loop: false,
        });
      tl.add({
        targets: [".home-head_explore .el-0-letter"],
        opacity: [0, 1],
        scale: [0, 1],
        duration: 1000,
        delay: ( el, i ) => 94*(i + 1)
      });
    }

    const checkTitle = () => {
      let textInView = document.querySelectorAll('.home-head_explore .title[data-view="inview-bottom"]').length;
      let textInView_top = document.querySelectorAll('.home-head_explore .title[data-view="outview-bottom"]').length;
      if (textInView > 0 && bool === false) {
        bool = true;

        if (arLink === link) {
          textAnimatedAR();
        } else {
          textAnimated();
        }
      }
      if (textInView_top > 0) {
        bool = false;
      }
    }
    checkTitle();

    window.addEventListener("scroll", checkTitle);
  }
  titleHomeExplore();
});

// dynamic wrappers for parallax =============
(function ( $ ) {
  $('.check-parallax').nextAll().wrapAll('<div class="parallax-overlay"><div class="parallax-overlay__wrap"></div></div>');
})(jQuery);




